@import "../../node_modules/bootstrap/scss/bootstrap";
$bootstrap-icons-font-src: url("../../node_modules/bootstrap-icons/font/fonts/bootstrap-icons.woff2") format("woff2"), url("../../node_modules/bootstrap-icons/font/fonts/bootstrap-icons.woff") format("woff") !default;
@import "../../node_modules/bootstrap-icons/font/bootstrap-icons";
//@import "node_modules/chartist/dist/scss/chartist";
@import "../../node_modules/toastr/toastr";

//test

.ct-series-a .ct-bar {
  /* Colour of your bars */
  stroke: #e7b00d;
  /* The width of your bars */
  stroke-width: 20px;
}

.under-navbar {
  margin-top: 4rem;
}

.form-filter {
  padding: 1em;
}
